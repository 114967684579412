import React from 'react';
import { useEmployee } from '../contexts/EmployeeContext';
import Header from '../components/Header';

const Schedule = () => {

    const {schedule } = useEmployee()


    return (
        <div className='wrap flex w-screen min-h-screen bg-tgBg justify-center font-Helvetica flex-wrap content-start select-none pt-10 pb-10'>
                <Header title='Schedule' showBackButton/>
            <div className="info w-11/12 text-center">
                {schedule?.map((day:any, index:any) => (
                    <div key={index} className='w-full text-tgText font-medium text-2xl mt-1'> {day.day_of_week}: {day.start_time} - {day.end_time} </div>
                ))}
            </div>
        </div>
    );
};

export default Schedule;