import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import { useEmployee } from '../contexts/EmployeeContext';
import { generateToken } from '../utils/tokenUtils';
import axios from 'axios';
import TimeRangeSlider from '../components/TimeRangeSlider';

function convertDecimalHours(decimalHours:any) {
    const hours = Math.floor(decimalHours); // Get the whole hours
    const minutes = Math.round((decimalHours - hours) * 60); // Convert the decimal part to minutes
    return `${hours} h ${minutes} m`;
}


const Stats = () => {

    const [stats,setStats] = useState<any>(null)
    const {employee} = useEmployee()
    const [range,setRange] = useState<("today" | "week" | "month")>('today')

    useEffect(() => {   
        const fetchStats = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/employee/${employee?.id}/stats`,{
                    headers:{
                        Authorization: `Bearer ${generateToken()}`,
                    }
                });
                setStats(response.data);
            } catch (error) {
                console.error('Error fetching stats data:', error);
            }
        }
        if (employee) {
            fetchStats();
        }
    }, [employee])


    if (stats){
        return (
            <div className='wrap flex w-screen min-h-screen bg-tgBg justify-center font-Helvetica flex-wrap content-start select-none pt-10 pb-10'>
                <Header title='Stats' showBackButton/>
                <TimeRangeSlider onRangeChange={setRange} />
                <div className="info w-9/12 text-center mt-3 flex flex-wrap justify-start">
                    <div className='text-2xl w-full text-start text-tgText'>wage: ${employee?.wage}</div>
                    <div className='text-2xl w-full text-start text-tgText'>time worked: {convertDecimalHours(stats.find((stat:any) => stat.period===range)?.hours_worked)}</div>
                    <div className='text-2xl w-full text-start text-tgText'>earned: ${Number(stats.find((stat:any) => stat.period===range).total_earned).toFixed(2)}</div>
                    <div className='text-2xl w-full text-start text-tgText'>paid: ${Number(stats.find((stat:any) => stat.period===range).total_paid).toFixed(2)}</div>
                    <div className='text-2xl w-full text-start text-tgText'>unpaid: ${Number(stats.find((stat:any) => stat.period===range).total_earned-stats.find((stat:any) => stat.period===range).total_paid).toFixed(2)}</div>
{}
                    
                </div>
            </div>
        );
    }else{
        return (
            <div className='wrap flex w-screen min-h-screen bg-tgBg justify-center font-Helvetica flex-wrap content-start select-none pt-10 pb-10'>
                <Header title='Stats' showBackButton/>
                <div className="info w-11/12 text-center">
                    Loading...
                </div>
            </div>
        );
    }
    
};

export default Stats;