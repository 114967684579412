import React, { createContext, useState, useContext, ReactNode } from 'react';

interface NavigationContextType {
  direction: 'forward' | 'backward';
  setDirection: (dir: 'forward' | 'backward') => void;
}

const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

export const NavigationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [direction, setDirection] = useState<'forward' | 'backward'>('forward');

  return (
    <NavigationContext.Provider value={{ direction, setDirection }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationDirection = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigationDirection must be used within a NavigationProvider');
  }
  return context;
};