import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'; // Use BrowserRouter and Routes
// import { Provider } from 'react-redux';
// import { store } from './redux/store';  
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NavigationProvider, useNavigationDirection } from './components/NavigationContext';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'; // Use BrowserRouter and Routes
import Auth from './pages/Auth';
import Main from './pages/Main';
import { EmployeeProvider } from './contexts/EmployeeContext';
import Schedule from './pages/Schedule';
import Stats from './pages/Stats';

const App: React.FC = () => {
  const location = useLocation();
  const { direction,setDirection } = useNavigationDirection();  // Get direction from context

  useEffect(() => {
    if (direction === 'backward') {
      // Set the direction back to 'forward' after the backward transition
      const timer = setTimeout(() => {
        setDirection('forward');
      }, 300); // Adjust the timeout to match the transition duration

      return () => clearTimeout(timer);
    }
  }, [direction, setDirection]);


  return (
    <div className="page-container">
    <EmployeeProvider>
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames={direction === 'forward' ? 'slide-forward' : 'slide-backward'}
        timeout={300}
      >
          <Routes location={location}>
            <Route path='/' element={<Auth/>}/>
            <Route path='/main' element={<Main/>}/>
            <Route path='/schedule' element={<Schedule/>}/>
            <Route path='/stats' element={<Stats/>}/>
          </Routes>
      </CSSTransition>
    </TransitionGroup>
    </EmployeeProvider>

    </div>
  );
};

// Root Component
const Root: React.FC = () => (
  // <React.StrictMode>
    // {/* <Provider store={store}> */}
      <NavigationProvider>
      <Router>
        <App />
      </Router>
      </NavigationProvider>
    // {/* </Provider> */}
  // {/* </React.StrictMode> */}
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Root />);