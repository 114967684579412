import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export function generateToken() {
    const timestamp = Math.floor(Date.now() / 10000); // Round to 10-second intervals
    const hash = CryptoJS.HmacSHA256(String(timestamp), SECRET_KEY).toString(CryptoJS.enc.Hex); // Use HmacSHA256
    return hash;
}
export function isTokenExpired(token) {
    try {
        const data = JSON.parse(atob(token));
        return Date.now() > data.expiresAt;
    } catch (error) {
        return true; 
    }
}