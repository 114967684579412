import React from 'react';
import backImg from '../img/back.svg'
import { useNavigate } from 'react-router-dom';
import { useNavigationDirection } from './NavigationContext';

interface HeaderProps {
    title: string;
    showBackButton: boolean;
}


const Header:React.FC<HeaderProps> = ({title,showBackButton}) => {

    const navigate = useNavigate();
    const {setDirection} = useNavigationDirection();

    return (
        <div className='flex content-center items-center justify-center  w-10/12'>
            {showBackButton && <div className=''> <img className='relative z-10' src={backImg} onClick={() => {setDirection('backward');navigate(-1)}} alt="" /> </div>}
            <div className='w-10/12 font-medium text-4xl text-center text-[#BABABA] mr-8'>{title}</div>
        </div>
    );
};

export default Header;