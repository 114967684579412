import React,{useState,useEffect} from 'react';
import { useEmployee } from '../contexts/EmployeeContext';
import { useNavigate } from 'react-router-dom';
import EmployeeStatusInfo from '../components/EmployeeStatusInfo';

const Main = () => {

    const { employee, status, actions } = useEmployee()
    const navigate = useNavigate();

    return (
        <div className='wrap flex w-screen min-h-screen bg-tgBg justify-center font-Helvetica flex-wrap content-start select-none pt-10 pb-10'>
            <EmployeeStatusInfo/>
            <div className="buttons flex justify-center flex-wrap">
                {status === 'idle'
                ?<button className={`w-3/5 rounded-lg bg-tgButton text-tgButtonText text-xl font-bold p-3 mt-5`} onClick={actions.clockIn}>Clock in</button>
                :<button disabled={status === 'on_break'} className={`w-3/5 rounded-lg bg-tgButton text-tgButtonText text-xl font-bold p-3 mt-5 ${status==='on_break'?"opacity-40 ":''}`} onClick={actions.clockOut}>Clock out</button>
                }
                {status === 'idle' || status === 'clocked_in'
                ?<button disabled={status === 'idle'} className={`w-3/5 rounded-lg bg-tgButton text-tgButtonText text-xl font-bold p-3 mt-5 ${status==='idle'?"opacity-40 ":''}`} onClick={actions.breakIn}>Start break</button>
                :<button className='w-3/5 rounded-lg bg-tgButton text-tgButtonText text-xl font-bold p-3 mt-5' onClick={actions.breakOut}>End break</button>
                }
                <button className={`w-3/5 rounded-lg bg-tgButton text-tgButtonText text-xl font-bold p-3 mt-5`} onClick={()=>navigate('/stats')} >View stats</button>
                <button onClick={()=>navigate('/schedule')} className={`w-3/5 rounded-lg bg-tgButton text-tgButtonText text-xl font-bold p-3 mt-5`} >View schedule</button>

            </div>
        </div>
    );
};

export default Main;