import React, { useState } from 'react';

interface TimeRangeSliderProps {
    onRangeChange?: (range: 'today' | 'week' | 'month') => void;
}

const TimeRangeSlider:React.FC<TimeRangeSliderProps> = ({ onRangeChange }) => {
    const [selected, setSelected] = useState('today');

    const handleClick = (range: 'today' | 'week' | 'month') => {
        setSelected(range);
        if (onRangeChange) {
            onRangeChange(range); // Call the parent callback with the selected range
        }
    };

    return (
        <div className="flex bg-tgHint rounded-xl p-1 w-full max-w-xs">
            <button
                onClick={() => handleClick('today')}
                className={`flex-1 py-2 rounded-xl text-center font-medium ${
                    selected === 'today' ? 'bg-tgButton text-tgButtonText' : 'text-text-tgButtonText'
                }`}
            >
                today
            </button>
            <button
                onClick={() => handleClick('week')}
                className={`flex-1 py-2 rounded-xl text-center font-medium ${
                    selected === 'week' ? 'bg-tgButton text-tgButtonText' : 'text-text-tgButtonText'
                }`}
            >
                week
            </button>
            <button
                onClick={() => handleClick('month')}
                className={`flex-1 py-2 rounded-xl text-center font-medium ${
                    selected === 'month' ? 'bg-tgButton text-tgButtonText' : 'text-text-tgButtonText'
                }`}
            >
                month
            </button>
        </div>
    );
};

export default TimeRangeSlider;