import { useEffect, useState } from 'react';

const useTg = () => {
  const [tg, setTg] = useState<WebApp | null>(null);

  useEffect(() => {
    if (window.Telegram.WebApp) {
      setTg(window.Telegram.WebApp);
    }
  }, []);

  return tg;
};

export default useTg;