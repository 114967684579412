import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { generateToken } from '../utils/tokenUtils';
import { useLocation } from 'react-router-dom';
import useTg from '../hooks/useTg';

// Define the types for employee, schedule, and status
type Status = 'idle' | 'clocked_in' | 'on_break';

interface Employee {
    id: number;
    name: string;
    position: string;
    wage:number;
}

interface Schedule {
    day_of_week: string;
    start_time: string;
    end_time: string;
    // Add other fields as needed
}

interface Shift {
    id:number;
    employee_id:number;
    wage:number;
    start_time:string;
    end_time:string;
}

interface Break {
    id:number;
    shift_id:number;
    start_time:string;
    end_time:string;
}

interface EmployeeContextType {
    employee: Employee | null;
    schedule: Schedule[] | null;
    status: Status;
    activeShift:Shift | null;
    activeBreak:Break | null;
    actions: {
        clockIn: () => void;
        breakIn: () => void;
        breakOut: () => void;
        clockOut: () => void;
        setEmployeeData: (employee: Employee) => void;
        setEmployeeStatus: (status: Status) => void;
    };
}


const EmployeeContext = createContext<EmployeeContextType | null>(null);

interface EmployeeProviderProps {
    children: ReactNode;
}

export const EmployeeProvider: React.FC<EmployeeProviderProps> = ({ children }) => {
    const [employee, setEmployee] = useState<Employee | null>(null);
    const [schedule, setSchedule] = useState<Schedule[] | null>(null);
    const [status, setStatus] = useState<Status>('idle');
    const [activeShift, setActiveShift] = useState<Shift | null>(null);
    const [activeBreak, setActiveBreak] = useState<Break | null>(null);
    const location = useLocation();
    const tg = useTg();


    // Fetch employee schedule
    async function fetchSchedule() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/employee/schedules/${employee?.id}`,{
                headers:{
                    Authorization: `Bearer ${generateToken()}`,
                }
            });
            setSchedule(response.data);
        } catch (error) {
            console.error('Error fetching schedule data:', error);
        }
    }
    //Fetch active shift
    async function fetchActiveShift() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/employee/${employee?.id}/active-shift`,{
                headers:{
                    Authorization: `Bearer ${generateToken()}`,
                }
            });
            setActiveShift(response.data);
        } catch (error) {
            console.error('Error fetching active shift:', error);
            
        }
    }
    //Fetch active break
    async function fetchActiveBreak() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/employee/${employee?.id}/active-break`,{
                headers:{
                    Authorization: `Bearer ${generateToken()}`,
                }
            });
            setActiveBreak(response.data);
        } catch (error) {
            console.error('Error fetching active break:', error);
            
        }
    }

    useEffect(() => {
        if (employee) {
            fetchSchedule();
            if (status !== 'idle') {
                fetchActiveShift();
                if (status === 'on_break') {
                    fetchActiveBreak();
                }
            }
        }

    }, [employee]);

    useEffect(() => {
        async function getEmployee() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/employee/${tg?.initDataUnsafe.user?.id}`,{
                    headers: {
                        Authorization: `Bearer ${generateToken()}`,
                    },
                });
                setEmployeeData({id:response.data.id, name:response.data.name, position:response.data.position, wage:response.data.wage});
                setEmployeeStatus(response.data.status);
    } catch (error) {
                console.error('Error fetching employee:', error);
            }
        }

        if (!employee && location.pathname!=='/' && tg?.initDataUnsafe?.user?.id){
            getEmployee()
        }

    }, [tg,location.pathname,employee]);



    const clockIn = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/employee/${employee?.id}/clock-in`,{},
            {headers: {
                Authorization: `Bearer ${generateToken()}`,
            },
            });
            setActiveShift(response.data.shift);
            setStatus('clocked_in');
        } catch (error) {
            console.log('Error clocking in',error)
        }
        setStatus('clocked_in');
    };

    const breakIn = async () =>{
        try {
            const response =await axios.post(`${process.env.REACT_APP_API_URL}/api/employee/${employee?.id}/break-in`,{},
            {headers: {
                Authorization: `Bearer ${generateToken()}`,
            },
            });
            setActiveBreak(response.data.break);
            setStatus('on_break');
            console.log(activeBreak)
            console.log(response.data)

        } catch (error) {
            console.error('Error starting break:', error);
        }
    }
    const breakOut = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/employee/${employee?.id}/break-out`,{},
            {headers: {
                Authorization: `Bearer ${generateToken()}`,
            },
            });
            setActiveBreak(null);
            setStatus('clocked_in');
        } catch (error) {
            console.error('Error ending break:', error);
            
        }
    }
    const clockOut = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/employee/${employee?.id}/clock-out`,{},
            {headers: {
                Authorization: `Bearer ${generateToken()}`,
            },
            });
            if (response.status === 200){
                setActiveShift(null);
                setStatus('idle');
            }
        } catch (error) {
            console.error('Error clocking out:', error);
        }
    };
    const setEmployeeData = (employee: Employee) => setEmployee(employee);
    const setEmployeeStatus = (status: Status) => setStatus(status);

    return (
        <EmployeeContext.Provider
            value={{
                employee,
                schedule,
                status,
                activeShift,
                activeBreak,
                actions: {
                    clockIn,
                    breakIn,
                    breakOut,
                    clockOut,
                    setEmployeeData,
                    setEmployeeStatus,
                },
            }}
        >
            {children}
        </EmployeeContext.Provider>
    );
};

export const useEmployee = () => {
    const context = useContext(EmployeeContext);
    if (!context) {
        throw new Error("useEmployee must be used within an EmployeeProvider");
    }
    return context;
};