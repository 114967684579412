import React from 'react';
import { useEmployee } from '../contexts/EmployeeContext';
import { log } from 'console';



const EmployeeStatusInfo: React.FC = () => {
    const { employee, schedule, status, actions, activeShift, activeBreak} = useEmployee();

    function isCurrentTimeInSchedule() {
        if (schedule){

            const currentDate = new Date();
            const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
            const currentTime = currentDate.getTime();
        
            const todaySchedule = schedule.find(entry => entry.day_of_week === currentDay);

            if (!todaySchedule) return false;

            const startDateTime = new Date();
            const endDateTime = new Date();
            startDateTime.setHours(parseTime(todaySchedule.start_time).hours, parseTime(todaySchedule.start_time).minutes);
            endDateTime.setHours(parseTime(todaySchedule.end_time).hours, parseTime(todaySchedule.end_time).minutes);

        
            return currentTime >= startDateTime.getTime() && currentTime <= endDateTime.getTime();
        }else{
            return false
        }
    }
    

    function parseTime(timeStr: string) {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':').map(Number);
        if (modifier === 'PM' && hours < 12) hours += 12;
        if (modifier === 'AM' && hours === 12) hours = 0;
        return { hours, minutes };
    }


    if (!employee) {
        return null;
    }else {

        

    switch (status) {
        case 'idle': {
            if (schedule) {
                return (
                    <div className="info w-11/12 text-center">
                        <div className="w-full font-medium text-4xl text-tgHint">idle</div>
                        {isCurrentTimeInSchedule()===true
                        ? <div className="w-full font-medium text-xl text-green-400 mt-1">It’s your scheduled work time now</div>
                        : <div className="w-full font-medium text-xl text-orange-400 mt-1">It’s not your scheduled work time now</div>}
                        
                    </div>
                );
            }else{
                return null;
            }
        }
        case 'clocked_in': {
            if (activeShift) {
                const date = new Date(activeShift!.start_time).toLocaleTimeString().slice(0,5);
                console.log(date);
                return (
                    <div className="info w-11/12 text-center">
                        <div className="w-full font-medium text-4xl text-green-400">clocked in</div>
                        <div className={`w-full font-medium text-2xl text-green-400 mt-1`}>
                            since {date}
                        </div>
                    </div>
                );
            }else{
                return null;
            }
        }
        case 'on_break': {
            if (activeBreak) {
                const date = new Date(activeBreak.start_time).toLocaleTimeString().slice(0,5);
        
                return (
                    <div className="info w-11/12 text-center">
                        <div className="w-full font-medium text-4xl text-yellow-400">on break</div>
                        <div className="w-full font-medium text-2xl text-yellow-400 mt-1">
                            since {date}
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        }
        default: {
            return null; 
        }
    }
    }
};

export default EmployeeStatusInfo;